import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UrologyAustinLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Singh"
    institution="Urology Austin"
    referralCode="UROLOGYAUSTIN"
  />
)

export default UrologyAustinLandingPage
